/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// You can delete this file if you're not using it

exports.onInitialClientRender = () => {
    window.north.init();
}

exports.onRouteUpdate = ({ location }) => {
    window.north.init();
    document.querySelector('html').classList.remove('offcanvas');
}
